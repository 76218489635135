import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./controls/App";
import { DataContextProvider, AppState } from "./context/DataContextProvider";

interface ConfigJson extends Partial<AppState> {
    baseUrl: string;
}

const main = () => {
    const root = document.getElementById("root");

    if (!root) {
        throw new Error("Cannot find root element!");
    }

    const { baseUrl, ...initialState } = JSON.parse(
        (root.firstElementChild as HTMLScriptElement).innerText,
    ) as ConfigJson;

    const corsUrl: string | undefined = process.env.NODE_ENV === "production" ? undefined : "http://corsproxy.cs";

    ReactDOM.render(
        <React.StrictMode>
            <DataContextProvider baseUrl={baseUrl} corsUrl={corsUrl} initialState={initialState}>
                <App />
            </DataContextProvider>
        </React.StrictMode>,
        root,
    );
};

function docReady(fn: () => void) {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

docReady(main);
