import * as React from "react";

type ClassValue = string | Record<string, boolean> | Array<ClassValue>;

const className = (value: ClassValue): string[] => {
    if (typeof value === "string") {
        return [value];
    }

    if (Array.isArray(value)) {
        return value.reduce<string[]>((p, o) => [...p, ...className(o)], []);
    }

    return Object.keys(value).reduce<string[]>((p, o) => (value[o] ? [...p, o] : p), []);
};

export const classNames = (...classes: ClassValue[]) => {
    const result = classes.reduce<string[]>((p, o) => [...p, ...className(o)], []);
    return result
        .filter((o, i) => result.indexOf(o) === i)
        .sort()
        .join(" ");
};

export const delay = 400;

export function usePrevious<T>(value: T): T {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref: any = React.useRef<T>();
    // Store current value in ref
    React.useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
