import * as React from "react";

type SpanProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
type SpanOrAnchorProps = SpanProps | AnchorProps;

function isAnchorProps(props: SpanOrAnchorProps): props is AnchorProps {
    return (props as AnchorProps).href !== undefined;
}

export const SpanOrAnchor: React.FunctionComponent<SpanOrAnchorProps> = ({ children, ...props }) => {
    if (isAnchorProps(props)) {
        return <a {...props}>{children}</a>;
    }

    return <span {...props}>{children}</span>;
};
