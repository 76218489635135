import * as React from "react";

import { AppState, OthersMeta, useDataContext } from "../context/DataContextProvider";
import { classNames } from "../utils";
import { OtherSubCategory } from "./OtherSubCategory";

function getMeta(state: AppState, othersMeta: Record<string, OthersMeta>): OthersMeta | undefined {
    return [state.level1selected, state.level2selected, state.level3selected]
        .map((o) => (o ? othersMeta[o] : undefined))
        .reduce<OthersMeta>(
            (p, o) => ({
                ...p,
                icon: o && o.icon ? o.icon : p.icon,
                icon_url: o && o.icon_url ? o.icon_url : p.icon_url,
                text: o && o.text ? o.text : p.text,
                image: o && o.image ? o.image : p.image,
            }),
            { icon: "", icon_url: "", text: "", image: "" },
        );
}

interface OtherCategoryProps {
    href: string;
}
export const OtherCategory: React.FunctionComponent<OtherCategoryProps> = ({ href }) => {
    const { links, othersMeta, state, setState } = useDataContext();
    const {
        [href]: { items, title, canonical },
    } = links;

    const isExpanded = React.useMemo(() => state.level1selected === href, [state, href]);

    const meta = React.useMemo(() => getMeta(state, othersMeta), [state, othersMeta]);

    const onMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(
        () => setState((s) => ({ ...s, level1next: href })),
        [href, setState],
    );

    const onMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(
        () => setState((s) => ({ ...s, level1next: null })),
        [setState],
    );

    return (
        <li
            className={classNames({
                "has-child": true,
                "is-current": state.level1current === href,
                "is-expanded": isExpanded,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <a href={canonical || href}>
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
            </a>
            {items && (
                <div
                    className={classNames({
                        "hco-popup": true,
                        "is-expanded": isExpanded,
                    })}
                >
                    <div>
                        <div className="hco-popup__categories">
                            <ul>
                                {items.map((o) => (
                                    <OtherSubCategory key={o} href={o} />
                                ))}
                            </ul>
                        </div>
                        {meta && (
                            <div className="hco-popup__description">
                                <div className="hco-popup__texts">
                                    <a href={meta.icon_url}>
                                        <img src={meta.icon} alt="" />
                                    </a>
                                    <p dangerouslySetInnerHTML={{ __html: meta.text }} />
                                </div>
                                <div
                                    className="hco-popup__image"
                                    style={{ backgroundImage: "url(" + meta.image + ")" }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </li>
    );
};
