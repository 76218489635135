import * as React from "react";
import { useSpring, a } from "@react-spring/web";
import useMeasure from "react-use-measure";

import { useDataContext } from "../context/DataContextProvider";
import { EshopPopup } from "./EshopPopup";
import { EshopSubCategory } from "./EshopSubCategory";
import { classNames } from "../utils";

export const EshopCategory: React.FunctionComponent = () => {
    const { eshopHref, links, state, setState } = useDataContext();

    const eshop = React.useMemo(() => links[eshopHref], [eshopHref, links]);

    const isExpanded = React.useMemo(() => state.level1selected === eshopHref, [state, eshopHref]);

    const [ref, { height: viewHeight }] = useMeasure();

    const style = useSpring({
        from: { height: 0 },
        to: { height: isExpanded ? viewHeight : 0 },
        leave: { height: 0 },
    });

    const onMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level1next: eshopHref }));
    }, [setState, eshopHref]);

    const onMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level1next: null }));
    }, [setState]);

    const showPopup = React.useMemo(() => {
        const link = state.level3selected ? links[state.level3selected] : undefined;
        return link && link.items && link.items.length > 0 && state.level3top > 0;
    }, [links, state.level3selected, state.level3top]);

    return (
        <div
            className={classNames({
                "header-categories-shop": true,
                "is-current": state.level1current === eshopHref,
                "is-expanded": isExpanded,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <a href={eshopHref} className="header-categories-shop__button">
                <span dangerouslySetInnerHTML={{ __html: eshop.title }} />
            </a>

            {isExpanded && eshop.items && (
                <>
                    <div className="header-categories-popup">
                        <a.div style={style} className="header-categories-menu__wrap">
                            <ul ref={ref} className="header-categories-menu">
                                {eshop.items.map((o) => (
                                    <EshopSubCategory key={o} href={o} />
                                ))}
                            </ul>
                        </a.div>
                    </div>
                    {showPopup && <EshopPopup />}
                </>
            )}
        </div>
    );
};
