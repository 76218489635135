import * as React from "react";

import { SpanOrAnchor } from "./SpanOrAnchor";
import { useDataContext } from "../context/DataContextProvider";
import { classNames } from "../utils";

interface EshopSubCategoryProps {
    href: string;
}
export const EshopSubCategory: React.FunctionComponent<EshopSubCategoryProps> = ({ href }) => {
    const { links, state, setState } = useDataContext();

    const isExpanded = React.useMemo(() => state.level2selected === href, [href, state.level2selected]);

    const { icon, items, title, canonical } = links[href];

    const meta = React.useMemo(() => (items ? items.map((href) => ({ href, ...links[href] })) : null), [items, links]);

    const onClick = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level2selected: s.level2selected === href ? null : href }));
    }, [setState, href]);

    const onMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(
        (e) => {
            const level3next = e.currentTarget.dataset.href as string;
            const level3nextTop = e.currentTarget.offsetTop;
            setState((s) => ({ ...s, level3next, level3nextTop }));
        },
        [setState],
    );

    const onMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level3next: null }));
    }, [setState]);

    return (
        <li
            data-href={canonical || href}
            className={classNames({
                "header-categories-menu__item": true,
                "is-expanded": isExpanded,
                "has-children": !!items,
            })}
        >
            <div className="header-categories-menu__item--header" onClick={items ? onClick : undefined}>
                <SpanOrAnchor href={items ? undefined : href} className="header-categories-menu__link">
                    <div className="header-categories-menu__icon">{icon && <img alt="" src={icon} />}</div>
                    <div className="header-categories-menu__label">
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                </SpanOrAnchor>
            </div>

            {isExpanded && meta && (
                <ul className="header-categories-submenu__list">
                    {meta.map((o) => (
                        <li
                            key={o.href}
                            data-href={o.href}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            className={classNames({
                                "header-categories-submenu__item": true,
                                "has-children": !!o.items && o.items.length > 0,
                                "is-expanded": state.level3selected === o.href,
                            })}
                        >
                            <a href={o.canonical || o.href} dangerouslySetInnerHTML={{ __html: o.title }} />
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};
