import * as React from "react";
import { useSpring, a } from "@react-spring/web";
import useMeasure from "react-use-measure";

import { useDataContext } from "../context/DataContextProvider";
import { classNames } from "../utils";

export const EshopPopup: React.FunctionComponent = () => {
    const {
        links,
        state: { level3selected, level3top, level4current },
        setState,
    } = useDataContext();

    const {
        [level3selected as string]: { items },
    } = links;

    const [ref, { width: viewWidth }] = useMeasure();

    const style = useSpring({
        from: { width: 0 },
        to: { width: viewWidth, y: level3top },
        leave: { width: 0 },
    });

    const meta = React.useMemo(() => {
        return items
            ? items.map((href) => {
                  const { icon, title, canonical } = links[href];
                  return { href, icon, title, canonical };
              })
            : null;
    }, [items, links]);

    const onMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level3next: level3selected, level3nextTop: s.level3top }));
    }, [setState, level3selected]);

    const onMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level3next: null }));
    }, [setState]);

    return (
        meta && (
            <a.div
                style={style}
                className="header-categories-popup-menu__wrap"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className="header-categories-popup-menu">
                    <ul ref={ref} className="header-categories-popup-menu__list">
                        {meta.map((o) => (
                            <li
                                key={o.href}
                                className={classNames({
                                    "header-categories-popup-menu__item": true,
                                    "is-selected": o.href === level4current,
                                })}
                            >
                                <a href={o.canonical || o.href} className="header-categories-popup-menu__link">
                                    <div className="header-categories-popup-menu__icon">
                                        {o.icon && <img src={o.icon} alt="" />}
                                    </div>
                                    <div className="header-categories-popup-menu__label">{o.title}</div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </a.div>
        )
    );
};
