import * as React from "react";

import { useDataContext } from "../context/DataContextProvider";
import { classNames } from "../utils";

interface OtherSubCategoryProps {
    href: string;
}
export const OtherSubCategory: React.FunctionComponent<OtherSubCategoryProps> = ({ href }) => {
    const {
        links,
        setState,
        state: { level2selected, level3selected },
    } = useDataContext();
    const { icon, items, title, canonical } = links[href];

    const meta = React.useMemo(() => {
        return items
            ? items.map((href) => {
                  const { title, canonical } = links[href];
                  return { href, title, canonical };
              })
            : null;
    }, [items, links]);

    const onMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level2selected: href }));
    }, [setState, href]);

    const onMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level2selected: null }));
    }, [setState]);

    const onItemMouseEnter = React.useCallback<React.MouseEventHandler<HTMLElement>>(
        (e) => {
            const selected = e.currentTarget.getAttribute("href");
            setState((s) => ({ ...s, level3selected: selected }));
        },
        [setState],
    );

    const onItemMouseLeave = React.useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        setState((s) => ({ ...s, level3selected: null }));
    }, [setState]);

    return (
        <li
            className={classNames({
                "is-selected": href === level2selected,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <a href={canonical || href} className="hco-main-child">
                {icon && <img alt="" src={icon} />}
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </a>
            {meta && (
                <ul>
                    {meta.map((o) => (
                        <li
                            className={classNames({
                                "is-selected": o.href === level3selected,
                            })}
                            key={o.href}
                        >
                            <a
                                href={o.canonical || o.href}
                                className="hco-sub-child"
                                dangerouslySetInnerHTML={{ __html: o.title }}
                                onMouseEnter={onItemMouseEnter}
                                onMouseLeave={onItemMouseLeave}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};
