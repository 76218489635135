import * as React from "react";
import axios from "axios";

import { Loader } from "../controls/Loader";

export interface StaticData {
    eshopHref: string;
    others: string[];
    links: Record<string, LinkInfo>;
    othersMeta: Record<string, OthersMeta>;
}

export interface LinkInfo {
    icon: string | undefined;
    title: string;
    items: string[] | undefined;
    canonical: string | undefined;
}

export interface OthersMeta {
    icon: string;
    icon_url: string;
    text: string;
    image: string;
}

export interface AppState {
    level1current: string | null;
    level4current: string | null;

    level1prev: string | null;

    level1next: string | null;
    level3next: string | null;

    level1selected: string | null;
    level2selected: string | null;
    level3selected: string | null;

    level3top: number;
    level3nextTop: number;
}

const initState: AppState = {
    level1current: null,
    level4current: null,

    level1prev: null,

    level1next: null,
    level3next: null,

    level1selected: null,
    level2selected: null,
    level3selected: null,

    level3top: 0,
    level3nextTop: 0,
};

export interface DataContextType extends StaticData {
    state: AppState;
    defaultState: AppState;
    setState: React.Dispatch<React.SetStateAction<AppState>>;
}

const contextDefault: DataContextType = {
    eshopHref: "",
    others: [],
    links: {},
    othersMeta: {},

    state: initState,
    defaultState: initState,
    setState: (o) => o,
};

const DataContext = React.createContext(contextDefault);
export const useDataContext = () => React.useContext(DataContext);

const getStaticData = async (url: string): Promise<StaticData> => {
    const response = await axios.get<StaticData>(url);
    return response.data;
};

interface DataContextProviderProps {
    baseUrl: string;
    corsUrl?: string;
    initialState: Partial<AppState>;
}

export const DataContextProvider: React.FunctionComponent<DataContextProviderProps> = ({
    baseUrl: _baseUrl,
    corsUrl,
    initialState,
    children,
}) => {
    const [staticData, setStaticData] = React.useState<StaticData | null>(null);
    const [error, setError] = React.useState<string | null>(null);

    const baseUrl = React.useMemo(() => {
        if (corsUrl) {
            //         http(s)://example.com/path?query=...
            // {corsUrl}/http(s)/example.com/path?query=...
            const match = _baseUrl.match(/(https?):\/\/(.*)/);
            if (match) {
                return corsUrl + "/" + match[1] + "/" + match[2];
            }
        }

        return _baseUrl;
    }, [_baseUrl, corsUrl]);

    const prepareData = React.useMemo<{ (data: StaticData): StaticData }>(() => {
        if (corsUrl) {
            return (o) => {
                const links = Object.keys(o.links).reduce((p, q) => {
                    const { icon, items, title, canonical } = o.links[q];
                    const iconFull = icon ? baseUrl + icon : undefined;
                    const canonicalFull = canonical ? baseUrl + canonical : undefined;
                    p[q] = { icon: iconFull, items, title, canonical: canonicalFull };
                    return p;
                }, {} as Record<string, LinkInfo>);

                const othersMeta = Object.keys(o.othersMeta).reduce((p, q) => {
                    const { icon, icon_url, image, text } = o.othersMeta[q];
                    p[q] = {
                        icon: baseUrl + icon,
                        icon_url: baseUrl + icon_url,
                        image: baseUrl + image,
                        text,
                    };
                    return p;
                }, {} as Record<string, OthersMeta>);

                return { ...o, links, othersMeta };
            };
        }

        return (o) => o;
    }, [baseUrl, corsUrl]);

    React.useEffect(() => {
        getStaticData(baseUrl + "/api")
            .then(prepareData)
            .then(setStaticData, setError);
    }, [baseUrl, corsUrl, prepareData]);

    const [state, setState] = React.useState<AppState>({ ...initState, ...initialState });

    if (error !== null) {
        return <div className="error">{error}</div>;
    }

    if (staticData === null) {
        return <Loader />;
    }

    return (
        <DataContext.Provider value={{ ...staticData, state, defaultState: { ...state }, setState }}>
            {children}
        </DataContext.Provider>
    );
};
