import * as React from "react";
import { useSpring, a } from "@react-spring/web";
import { debounce } from "ts-debounce";

import { useDataContext } from "../context/DataContextProvider";
import { EshopCategory } from "./EshopCategory";
import { OtherCategory } from "./OtherCategory";
import { delay } from "../utils";

export const App: React.FunctionComponent = () => {
    const { state, setState, others } = useDataContext();

    const updateLevel1 = React.useMemo(() => {
        return debounce(() => setState((s) => ({ ...s, level1selected: s.level1next })), delay);
    }, [setState]);

    React.useEffect(() => {
        updateLevel1();
    }, [state.level1next, updateLevel1]);

    const updateLevel3 = React.useMemo(() => {
        return debounce(
            () => setState((s) => ({ ...s, level3selected: s.level3next, level3top: s.level3nextTop })),
            delay,
        );
    }, [setState]);

    React.useEffect(() => {
        updateLevel3();
    }, [state.level3next, updateLevel3]);

    const showOverlay = React.useMemo(() => !!state.level1selected, [state]);

    const style = useSpring({
        from: { opacity: 0 },
        to: async (next) => {
            await next({ height: showOverlay ? window.innerHeight : 0, immediate: true });
            await next({ opacity: showOverlay ? 1 : 0 });
        },
    });

    return (
        <div className="header-categories">
            <EshopCategory />

            <div className="header-categories__others">
                <ul className="header-categories-others">
                    {others.map((o) => (
                        <OtherCategory key={o} href={o} />
                    ))}
                </ul>
            </div>

            <a.div style={style} className="hco__overlay" />
        </div>
    );
};
